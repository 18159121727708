// Add new course catagories here
// The order they appear in here is how they will appear on the
// admin's course management page and resident/faculty all courses page
const courseTypes = Object.freeze({
  GETTING_STARTED: { id: 'GETTING_STARTED', title: 'Getting Started' },
  FACULTY: { id: 'FACULTY', title: 'Faculty Development' },
  SOFT_SKILL: { id: 'SOFT_SKILL', title: 'Soft Skill Development' },
  SKILL: { id: 'SKILL', title: 'Skill Development' },
  PROCEDURAL: { id: 'PROCEDURAL', title: 'Procedural Development' }
})

export default courseTypes

export function isPrepCourse (courseType) {
  if (courseType === courseTypes.GETTING_STARTED.id ||
     courseType === courseTypes.FACULTY.id ||
     courseType === courseTypes.SOFT_SKILL.id) {
    return true
  }
  return false
}
